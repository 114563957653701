import React from "react";
import "./Section2.css";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";

import wrapper1 from "../../assets/depressed_woman.webp";

const Section2 = () => {
  return (
    <>
      <div className="section2 ">
        <div className="section2-content container">


        <Fade right>
          <div className="section2-content-left">
            <img src={wrapper1} alt="Mental Health Therapy Session" loading="lazy" />
          </div>
          </Fade>


        <Fade left>
          <div className="section2-content-right">
            <h1>LET'S TALK MENTAL HEALTH</h1> <br />
            <h2>
              Begin Your Path to Wellness.
            </h2>
            <br />
            <p>
            At Contra Costa Rehab, we specialize in holistic mental health care, addressing the crucial connection between mental well-being and overall health. Our personalized treatment programs cater to each individual's unique journey, offering a range of options from individual therapy to group support sessions tailored to your comfort level.
            </p> <br />
            <p>Our dedicated practitioners stay at the forefront of mental health research and evidence-based treatment, ensuring you receive the most effective care available. Take the first step toward lasting emotional wellness—explore our services and schedule a consultation with our compassionate experts today.</p><br />
            <p><i>You're not alone—let's build a brighter, healthier future together.</i></p>
            <br />
            <div>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/mental-health">
                        <button>Learn More</button>
                      </Link>
                    </LinkRoll>
                  </div>
          </div>
          </Fade>

        </div>
      </div>
    </>
  );
};

export default Section2;
