import React, { useRef, useState } from "react";
import "./Contact2.css";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const form = useRef();
  const [buttonText, setButtonText] = useState("Submit");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    const formData = new FormData(form.current);
    const name = formData.get("name").trim();
    const email = formData.get("email").trim();
    const phone = formData.get("phone").trim();
    const subject = formData.get("subject").trim();
    const message = formData.get("message").trim();

    if (!name) {
      newErrors.name = "Name is required";
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(name)) {
      newErrors.name = "Name must contain only letters and spaces";
      isValid = false;
    }

    if (!email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      newErrors.email = "Enter a valid email address";
      isValid = false;
    }

    if (!phone) {
      newErrors.phone = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10,15}$/.test(phone)) {
      newErrors.phone = "Enter a valid phone number";
      isValid = false;
    }

    if (!subject) {
      newErrors.subject = "Subject is required";
      isValid = false;
    }

    if (!message) {
      newErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    emailjs
      .sendForm(
        "service_bdfl0ie",
        "template_t2l2zol",
        form.current,
        "CLcHWAKSemVMd1_sU"
      )
      .then(
        (result) => {
          console.log(result.text);
          setButtonText("Message Sent");
          setTimeout(() => setButtonText("Submit"), 3000); // Reset after 3 seconds
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <>
    <div className="contactForm">
      <div className="contact2-header contact2-container">
        <h1>DO YOU NEED URGENT HELP?</h1>
        <p>Our Addiction and Recovery Support team is available to assist you.</p>
      </div>

      <div className="contact2-container">
        <div className="contact-form-content">
          <div className="left-side">
            <div className="address details">
              <i className="fas fa-map-marker-alt"></i>
              <div className="topic">Address</div>
              <br />
              <div className="text-one"> 3700 Sunset Ln, Suite 2,</div>
              <div className="text-two">Antioch, CA 94509</div>
            </div>

            <div className="phone details">
              <i className="fas fa-phone-alt"></i>
              <div className="topic">Phone</div>
              <br />
              <div className="text-one">
                <a href="tel:3237920502"> +1 (323) 792-0502</a>
              </div>
            </div>

            <div className="email details">
              <i className="fas fa-envelope"></i>
              <div className="topic">Email</div>
              <br />
              <div className="text-one"> Info@ContraCostaRehab.com</div>
            </div>
          </div>

          <div className="right-side">
            <div className="topic-text">Send us a message</div>
            <br />
            <p className="topic-text-p">
              Please call our admission department if you have any questions.
            </p>
            <form ref={form} onSubmit={sendEmail}>
              <div className="input-box">
                <input type="text" name="name" placeholder="Your Name" required />
                {errors.name && <p className="error">{errors.name}</p>}
              </div>
              <div className="input-box">
                <input type="text" name="email" placeholder="Your Email" required />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="input-box">
                <input type="text" name="phone" placeholder="Your Phone" required />
                {errors.phone && <p className="error">{errors.phone}</p>}
              </div>
              <div className="input-box">
                <input type="text" name="subject" placeholder="Subject" required />
                {errors.subject && <p className="error">{errors.subject}</p>}
              </div>
              <div className="input-box message-box">
                <textarea name="message" rows="4" placeholder="How can we help?" required></textarea>
                {errors.message && <p className="error">{errors.message}</p>}
              </div>
              <input className="contact-btn" type="submit" value={buttonText} />
            </form>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default ContactForm;
