import React from "react";
import "./MissionHome.css";

const MissionHome = () => {
  return (
    <>
      <div className="mission-home">
        <div className="mission-home-content container">
          <div>
          <h1>OUR MISSION</h1>
          <h2>A Safe and Supportive Start to Your Healing Journey</h2>
          <p><br/>
          At our center, we provide every individual with a quiet, calm, and safe environment to begin their path to recovery. Our philosophy is rooted in the belief that empowering individuals in their care leads to lasting transformation. Through comprehensive addiction treatment and behavioral health services, we aim to reduce the prevalence of mental health challenges and promote long-term well-being.
          </p> <br/>
          <p>By offering personalized care and evidence-based therapies, we help individuals regain control of their lives and achieve an improved quality of life.</p><br/>
          <p><i>"It's okay not to be okay, but it's not okay to stay that way." — Unknown</i></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionHome;
